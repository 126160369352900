html{
  height: 100%;
  min-height: 100%;
 
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: #ffffff;


  
}

footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

footer ul {
  text-align: center;
}

@media (min-width: 600px) {
  footer {
    flex-direction: row;
    justify-content: space-between;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.containerlogo {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center;    /* Center vertically */
  height: 100%;           /* Make sure the container has some height */
}

img.bg {
   
  height: 500px;
  width: 500px;

}
s
img{
  width: 750px;
  height: 550px;
}

.imgkits{
  width: 200px;
  height: 150px;
}

.sliderempresa{
  width: 800px;
  height: 1000px;
}

.imgplaca{
  width: 500px;
  height: 250px;
}

.button{
  background-color: crimson;
  
}
.button:hover{
  background-color: firebrick;
}
.button:focus{
  background-color: firebrick;
}

a{
  color: white;
}
a:link{
  color: white;
}
a:visited{
  color: white;
}
a:hover{
  color: white;
}
a:active{
  color: white;
}

.icon{
  width: 50px;
  height: 50px;
}

.placas {
  max-width: 100px; /* Limit the width */
  height: auto;     /* Maintain aspect ratio */
  border-radius: 5px; /* Optional: Add rounded corners */
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); /* Optional: Add shadow */
}

p {
  margin-bottom: 20px; /* Adjust spacing as needed */
  line-height: 1.5;    /* Improve readability */
}